@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@100;700&family=Noto+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.circular-portrait {
  border-radius: 50%
}

.name {
  font-family: 'Noto Sans', sans-serif;
  font-size: 300%;
  color: #0091D5;
  letter-spacing: 3px;
}

.CenterCard {
  max-width: 800px;
  margin: 0 auto;
  padding: 50px;
  font-family: 'Archivo', sans-serif;
}

.aboutMe {
  text-align: left;
}

.navbar {
  margin: auto;
  max-width: 600px;
  padding: 20px;
  font-family: 'Archivo', sans-serif;
}

.footer {
  margin: auto;
  max-width: 800px;
}

.footerText {
  font-family: 'Archivo', sans-serif;
}

.icon {
  width: 50px;
  height: 50px;
}

.WorkExperience {
  width: 80px;
  height: 80px;
}

h3 {
  margin-bottom: 0;
  font-weight: bold;
  color: #173F5F;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
  color: #3CAEA3;
}

h5 {
  margin-top: 0;
  color: black;
}

a:link {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: underline;
  color: black;
}

a:visited{
  color:black;
}

.workExperience {
  text-align: left;
}

.activeLink {
  font-weight: bold;
  font-size: large;
}

.resume-download {
  color: black;
}

@media screen and (max-width: 480px) {
  .CenterCard .MuiGrid-item{
    max-width: 100%;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    margin: 0 auto;
    padding: 50px;
    font-family: 'Archivo', sans-serif;
  }
}

